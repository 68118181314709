export enum ContentTextColor {
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}

export enum ContentShowType {
  PC,
  MOBILE,
  BOTH,
}

export enum ContentTextSize {
  SIZE_16 = 16,
  SIZE_22 = 22,
  SIZE_30 = 30,
  SIZE_100 = 100,
}

/* @NOTE ContentRowType 규칙
 * 첫번째 숫자: 그리드 단
 * 두번째 숫자: fluid(fullWidth) 면 1, 아니면 0 (좌우 5% 여백)
 * 세번째 숫자: hasSpacing 이면 1, 아니면 0 (좌우 6px 여백)
 * 4,5번째 숫자: 앞 3자리 그룹 내에서의 시퀀스(0부터 증가)
 *
 * @NOTE Type 규칙
 * GRID{SIZE}_{INVERTED}_{feature}_{SPACING}
 * _SPACING(suffix): 세번째 숫자가 1인경우
 * _INVERTED: 기본타입의 그리드 셀 좌우 반전
 */
export enum ContentRowType {
  GRID0_SPACING = '00000',
  GRID0_HORIZONTAL = '00001',
  GRID0_EMPTY = '00002',
  GRID0_META = '00003',
  GRID0_COMPONENT = '00004',

  GRID1_HISTORY_LINE = 10000,

  GRID1_FULL_VIDEO = 11000,
  GRID1_FULL_IMAGE = 11001,
  GRID1_FULLSCREEN_INTRO = 11002,
  GRID1_CENTER_ALIGNED_TEXT = 11003,
  GRID1_FULL_TAB = 11004,
  GRID1_FULL_BACKGROUND_TAB = 11005,
  GRID1_FULL_BACKGROUND_SLIDER = 11006,
  GRID1_STORE_BUTTONS = 11007,
  GRID1_STATIC_INTRO = 11008,
  GRID1_PATTERN_BACKGROUND = 11009,
  GRID1_CENTER_ALIGNED_TITLE = 11010,
  GRID1_FADING_SLIDER = 11011,
  GRID1_FULL_BACKGROUND_IMAGE = 11012,
  GRID1_AGREEMENT = 11013,
  GRID1_TITLE_WITH_TEXT = 11014,
  GRID1_FULL_NEWS_LATEST = 11015,

  GRID2_TITLE_SET = 20000,
  GRID2_GALLERY = 20001,
  GRID2_ACCORDION = 20002,
  GRID2_RIGHT_BUTTONS = 20003,
  GRID2_RIGHT_SINGLE_BUTTON = 20004,
  GRID2_FULL_REPORT = 20011,
  GRID2_FULL_REPORT_TAB = 20005,
  GRID2_IMAGE_WITH_TEXT = 20006,
  GRID2_IMAGE_WITH_TEXT_SPACING = 20100,
  GRID2_INVERTED_IMAGE_WITH_TEXT = 20007,
  GRID2_INVERTED_IMAGE_WITH_TEXT_SPACING = 20101,
  GRID2_IMAGE_WITH_TAB = 21001,
  GRID2_BACKGROUND_IMAGE_WITH_TEXT = 21002,
  GRID2_INVERTED_BACKGROUND_IMAGE_WITH_TEXT = 21003,

  GRID2_VIDEO_WITH_TEXT = 20008,
  GRID2_VIDEO_WITH_TEXT_SPACING = 20102,
  GRID2_INVERTED_VIDEO_WITH_TEXT = 20009,
  GRID2_INVERTED_VIDEO_WITH_TEXT_SPACING = 20103,

  GRID2_VIDEO_CONTENTS_WITH_TEXT = 20010,
  GRID2_VIDEO_CONTENTS_WITH_TEXT_SPACING = 20104,
  GRID2_INVERTED_VIDEO_CONTENTS_WITH_TEXT = 20013,
  GRID2_INVERTED_VIDEO_CONTENTS_WITH_TEXT_SPACING = 20105,

  GRID2_TITLE_WITH_CONTENTS = 21004,
  GRID2_TITLE_WITH_CONTENTS2 = 21005,
  GRID2_ICON_WITH_CONTENTS = 20012,

  GRID3_MEDIA_CARD = 30000,
  GRID3_CARD_LINK_LIST = 30101,
}

export type ContentColumnType = ContentRowType

export enum EffectType {
  WIPE_RIGHT,
  FADE_IN,
  FADE_UP,
  FADE_RIGHT,
  FADE_SHORT_LEFT,
  FADE_SHORT_RIGHT,
  ZOOM,
}

export enum ImageFitType {
  CONTAIN = 'contain',
  COVER = 'cover',
}

export enum ContentItemType {
  IMAGE,
  TEXT,
  VIDEO,
  TITLE,
  TITLE_HEADING,
  SUB_TITLE,
  BUTTON,
  HISTORY,
  TAB,
  BACKGROUND_IMAGE,
  HASHMAP,
  IFRAME,
  TABLE_BORDERED,
  LIST,
  DESCRIPTIONS,
  CATEGORY,
  LINK,
  META,
  COMPONENT,
}

export enum AnchorTargetType {
  BLANK = '_blank',
  SELF = '_self',
}

export enum ContentSpacingRowType {
  _200_80 = '_200_80',
  _180_120 = '_180_120',
  _120_60 = '_120_60',
  _100_60 = '_100_60',
  _100_80 = '_100_80',
  _80_60 = '_80_60',
  _60_60 = '_60_60',
  _50_24 = '_50_24',
  _40_16 = '_40_16',
  _30_12 = '_30_12',
  _30_40 = '_30_40',
  _24_12 = '_24_12',
  _20_12 = '_20_12',
}

/**
 * @see https://docs.google.com/spreadsheets/d/1MyfCuiOrjIIO0Sk16J5mGxwcVN9cZ5P9nJAEvG9Dc3A/edit#gid=792518762
 */
export enum ContentLogPageName {
  HOME = '홈',
  KAKAOMOBILITY_STORY = '홈_카카오모빌리티_카카오모빌리티이야기',
  RECRUIT_STORY = '홈_카카오모빌리티_영입이야기',
  TECHNOLOGY_STORY = '홈_기술과안전_이동기술이야기',
  SAFETY_STORY = '홈_기술과안전_안전노력이야기',
  KAKAOT_BIZ = '홈_서비스_카카오T비즈니스',
  AUTO = '홈_서비스_카카오모빌리티자율주행',
  CS = '홈_안내_고객지원',
  ROBOTICS = '홈_서비스_카카오모빌리티_로보틱스',
  REPORT = '홈_뉴스룸_카카오모빌리티리포트',
  NEMO = '홈_안내_NEMO',
  ROAD_HEROS = '홈_안내_도로위히어로즈',
  COVID_19 = '홈_COVID19대응',
  ADVERTISEMENT = '홈_안내_광고안내',
  POPUP = '홈_안내_팝업',
  PRIVACY_POLICY = '홈_개인정보처리방침',

  TOGETHER = '홈_동행과상생',
  TOGETHER_PARTNER = '홈_동행과상생_파트너상생',
  TOGETHER_LOCAL_COMMUNITY = '홈_동행과상생_지역사회상생',
  TOGETHER_ECO_FRIENDLY = '홈_동행과상생_친환경',
  TOGETHER_CONTENT = '홈_동행과상생_컨텐츠',

  KAKAOT = '홈_서비스_카카오T_홈',
  KAKAOT_TAXI = '홈_서비스_카카오T_택시',
  KAKAOT_DRIVER = '홈_서비스_카카오T_대리',
  KAKAOT_BIKE = '홈_서비스_카카오T_바이크',
  KAKAOT_PARKING = '홈_서비스_카카오T_주차',
  KAKAOT_FLIGHT = '홈_서비스_카카오T_항공',
  KAKAOT_TRAIN = '홈_서비스_카카오T_기차',
  KAKAOT_BUS = '홈_서비스_카카오T_시외버스',
  KAKAOT_RENTCAR = '홈_서비스_카카오T_렌터카',
  KAKAOT_SHUTTLE = '홈_서비스_카카오T_셔틀',
  KAKAOT_GLOBAL = '홈_서비스_카카오T_해외여행',
  KAKAOT_GUAM_TAXI = '홈_서비스_카카오T_괌택시',
  KAKAOT_QUICK = '홈_서비스_카카오T_퀵택배',
  KAKAOT_MYCAR = '홈_서비스_카카오T_마이카',
  KAKAOT_PET = '홈_서비스_카카오T_펫',

  NAVI = '홈_서비스_카카오내비_내비',
  NAVI_MYCAR = '홈_서비스_카카오내비_내차관리',

  NOTICE = '홈_안내_공지사항',
  NOTICE_CONTENT = '홈_안내_공지사항_콘텐츠',

  NEWSROOM_ALL = '홈_뉴스룸_전체보기',
  NEWSROOM_NEWS = '홈_뉴스룸_뉴스',
  NEWSROOM_MORE = '홈_뉴스룸_더많은이야기',
  NEWSROOM_CONTENT = '홈_뉴스룸_콘텐츠',
}

export enum ContentLogEventActionName {
  HOME_GNB_LINK_CLICK = '홈_GNB메뉴_클릭',
  HOME_LNB_LINK_CLICK = '홈_LNB메뉴_클릭',
  HOME_MOBILE_LNB_LINK_CLICK = '홈_모바일_NB메뉴_클릭',

  HOME_CONTENT_KAKAOMOBILITY_STORY = '홈_콘텐츠_카카오모빌리티이야기',
  HOME_CONTENT_TECHNOLOGY_STORY = '홈_콘텐츠_이동기술이야기',
  HOME_CONTENT_SAFETY_STORY = '홈_콘텐츠_안전노력이야기',
  HOME_CONTENT_SERVICE = '홈_콘텐츠_서비스',
  HOME_CONTENT_RECRUIT_STORY = '홈_콘텐츠_영입이야기',

  RECRUIT_SITE = '홈_카카오모빌리티_영입이야기_영입사이트',
  RECRUIT_JOBLIST = '홈_카카오모빌리티_영입이야기_영입공고',
  RECRUIT_INSIDEKAMO = '홈_카카오모빌리티_영입이야기_기업문화',

  TECHNOLOGY_STORY_ALLOCATE_TAXI = '홈_기술과안전_이동기술이야기_택시배차시스템',
  TECHNOLOGY_STORY_AUTO = '홈_기술과안전_이동기술이야기_자율주행모빌리티연구',
  TECHNOLOGY_STORY_DRIVER = '홈_기술과안전_이동기술이야기_대리운전수요예측시스템',
  TECHNOLOGY_STORY_PARKING = '홈_기술과안전_이동기술이야기_주차장만차예측기능',
  TECHNOLOGY_STORY_FIN = '홈_기술과안전_이동기술이야기_세계최초FIN기술개발',
  TECHNOLOGY_STORY_APP_METER = '홈_기술과안전_이동기술이야기_중형택시최초앱미터기도입',
  TECHNOLOGY_STORY_EMERGENCY = '홈_기술과안전_이동기술이야기_119긴급출동알림서비스',

  ROBOTICS_MAIL_SEND = '홈_서비스_카카오모빌리티_로보틱스_메일보내기',
  ROBOTICS_INTRO_DOWNLOAD = '홈_서비스_카카오모빌리티_로보틱스_상품소개서',

  CS_WEB = '홈_안내_고객지원_고객지원사이트',
  CS_KAKAOT_CHATBOT = '홈_안내_고객지원_카카오T고객센터챗봇&상담톡',
  CS_DRIVER_CHATBOT = '홈_안내_고객지원_카카오T대리챗봇&상담톡',
  CS_DRIVER_FLEXER_CHATBOT = '홈_안내_고객지원_카카오T대리기사용챗봇&상담톡',
  CS_NAVI_CHATBOT = '홈_안내_고객지원_카카오내비챗봇&상담톡',
  CS_PICKER_CHATBOT = '홈_안내_고객지원_카카오T픽커챗봇&상담톡',
  REPORT = '홈_뉴스룸_카카오모빌리티리포트',
  NEMO = '홈_안내_NEMO',
  ROAD_HEROS = '홈_안내_도로위히어로즈',

  KAKAOT_SERVICE_TAXI_BLUE = '홈_서비스_카카오T_택시_블루',
  KAKAOT_SERVICE_TAXI_VENTI = '홈_서비스_카카오T_택시_벤티',
  KAKAOT_SERVICE_TAXI_DELUXE = '홈_서비스_카카오T_택시_모범',
  KAKAOT_SERVICE_TAXI_BLACK = '홈_서비스_카카오T_택시_블랙',

  KAKAOT_SERVICE_DRIVER_STANDARD = '홈_서비스_카카오T_대리_스탠다드',
  KAKAOT_SERVICE_DRIVER_PREMIUM = '홈_서비스_카카오T_대리_프리미엄',
  KAKAOT_SERVICE_DRIVER_ECONOMY = '홈_서비스_카카오T_대리_이코노미',

  KAKAOT_SERVICE_GOOGLE_PLAY = '홈_서비스_카카오T_구글플레이',
  KAKAOT_SERVICE_APPSTORE = '홈_서비스_카카오T_앱스토어',
  KAKAOT_SERVICE_GUIDE = '홈_서비스_카카오T_카카오T가이드',
  KAKAOT_SERVICE_CS_WEB = '홈_서비스_카카오T_고객지원사이트',
  KAKAOT_SERVICE_KAKAOT_CREW = '홈_서비스_카카오T_카카오T블루크루',
  KAKAOT_SERVICE_KAKAOT_DRIVER = '홈_서비스_카카오T_카카오T대리기사',
  KAKAOT_SERVICE_KAKAOT_PARKING_MANAGER = '홈_카카오T_카카오T주차관리자',
  KAKAOT_SERVICE_KAKAOT_BIKE = '홈_서비스_카카오T_카카오T바이크제휴',
  KAKAOT_SERVICE_KAKAOT_SHUTTLE = '홈_서비스_카카오T_카카오T셔틀제휴',
  KAKAOT_SERVICE_KAKAOT_PET_MATE = '홈_서비스_카카오T_카카오T펫메이트',

  KAKAOT_SERVICE_BIKE_INTRODUCTION = '홈_서비스_카카오T_바이크_바이크소개서',

  NAVI_SERVICE_GOOGLE_PLAY = '홈_서비스_카카오내비_구글플레이',
  NAVI_SERVICE_APPSTORE = '홈_서비스_카카오내비_앱스토어',
  NAVI_SERVICE_GUIDE = '홈_서비스_카카오내비_카카오내비가이드',
  NAVI_SERVICE_CS_WEB = '홈_서비스_카카오내비_고객지원사이트',

  NEMO_SITE = '홈_카카오모빌리티_카카오모빌리티이야기_NEMO사이트바로가기',
  NEMO_SKETCH = '홈_카카오모빌리티_카카오모빌리티이야기_NEMO스케치영상바로가기',
  NEMO_BEHIND = '홈_카카오모빌리티_카카오모빌리티이야기_NEMO비하인드소개글바로가기',

  KAKAOMOBILITY_STORY_HISTORY_MORE = '홈_카카오모빌리티_카카오모빌리티이야기_연혁더보기',
  KAKAOMOBILITY_STORY_TECHNOLOGY = '홈_카카오모빌리티_카카오모빌리티이야기_이동기술이야기',
  KAKAOMOBILITY_STORY_SAFETY = '홈_카카오모빌리티_카카오모빌리티이야기_안전노력이야기',

  KAKAOT_BIZ_WEB = '홈_서비스_카카오T비즈니스_카카오T비즈니스사이트',
  KAKAOT_BIZ_GUIDE = '홈_서비스_카카오T비즈니스_카카오T가이드',
  KAKAOT_BIZ_CS_WEB = '홈_서비스_카카오T비즈니스_고객지원사이트',

  COVID19_OECD = '홈_COVID19대응_OECD도주목한카카오모빌리티사례',
  COVID19_KAKAOT_TAXI = '홈_COVID19대응_카카오T택시대응',
  COVID19_KAKAOT_BIKE = '홈_COVID19대응_카카오T바이크대응',
  COVID19_KAKAOT_DRIVER = '홈_COVID19대응_카카오T대리대응',
  COVID19_KAKAOT = '홈_COVID19대응_카카오T대응',

  ADVERTISEMENT_WEBINA = '광고마케팅웨비나다시보기_카카오모빌리티광고의현재와미래',
  ADVERTISEMENT_DISPLAY = '디스플레이광고_높은주목도와유니크한지면구성',
  ADVERTISEMENT_TAXI = '택시광고_탑승객의이동을즐겁게해줄_택시내부디스플레이광고',
  ADVERTISEMENT_OUTDOOR = '옥외광고_코엑스및올리브영,기아오토큐방문객대상맞춤형광고집행',
  ADVERTISEMENT_GUIDE = '광고제작가이드_카카오모빌리티광고규정준수사항',
  ADVERTISEMENT_REWORD = '리워드광고_광고참여혜택으로카카오T포인트제공',

  ADVERTISEMENT_INQUIRY = '광고문의하기',

  FOLLOWUS_BRUNCH = '홈_FollowUs_Brunch',
  FOLLOWUS_FACEBOOK = '홈_FollowUs_Facebook',
  FOLLOWUS_YOUTUBE = '홈_FollowUs_Youtube',
  FOLLOWUS_KAKAOTV = '홈_FollowUs_KakaoTV',
  FOLLOWUS_INSTAGRAM = '홈_FollowUs_Instagram',
}

export enum CategoryLabel {
  ALL = '전체',
  WORTH = '가치 있는 동행',
  PARTNER = '파트너랑 둘이_TWO',
  LOCAL_COMMUNITY = '소외는 없도록_ZERO',
  ECO_FRIENDLY = '지구는 하나니까_ONE',
}
