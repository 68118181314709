import React, { useEffect } from 'react'
import { AppProps } from 'next/app'

import GlobalStyle from '@/styles/global'
import { AppProvider } from '@/contexts'
import UnsupportedBrowser from '@/components/UnsupportedBrowser'
import useKakaoAgent from '@/hooks/useKakaoAgent'
import NoticeContainer from '@/containers/NoticeContainer'
import useLoggingPageEffect from '@/hooks/useLoggingPageEffect'
import useScrollToHashEffect from '@/hooks/useScrollToHashEffect'
import { SWRConfig } from 'swr'
import OnlyClient from '@/components/OnlyClient'
import { Tiara, TiaraDeployment } from '@kakaomobility/tui-core'
import { ToastProvider } from '@kakaomobility/tui-react'
import GlobalHead from '@/components/GlobalHead'
import NextNProgress from 'nextjs-progressbar'
import { STYLE_COLOR } from '@/styles/variables'
import GoogleAnalytics from '@/components/GoogleAnalytics'

function CompanyWebsiteApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    void Tiara.init({
      svcDomain: process.env.TIARA_SERVICE_DOMAIN,
      deployment: process.env.TIARA_DEPLOYMENT as TiaraDeployment,
    })
  }, [])

  useLoggingPageEffect()
  useScrollToHashEffect()

  const { isUnsupportedBrowser } = useKakaoAgent()

  if (isUnsupportedBrowser) {
    return <UnsupportedBrowser />
  }

  return (
    <SWRConfig
      value={{
        dedupingInterval: 24 * 60 * 60 * 1000,
        fallback: pageProps?.fallback || {},
      }}
    >
      <GlobalHead />
      <GoogleAnalytics />
      <AppProvider>
        <ToastProvider>
          <NextNProgress color={STYLE_COLOR.YELLOW} />
          <Component {...pageProps} />
        </ToastProvider>
        <GlobalStyle />
        <OnlyClient>
          <NoticeContainer />
        </OnlyClient>
      </AppProvider>
    </SWRConfig>
  )
}

export default CompanyWebsiteApp
