import 'reset-css/reset.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { createGlobalStyle } from 'styled-components'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { STYLE_FONT_FAMILY } from '@/styles/fonts'

export const GLOBAL_HIDDEN_SCROLL_MOBILE_CLASS = `_${Date.now().toString(32)}`

const GlobalStyle = createGlobalStyle`
  html.${GLOBAL_HIDDEN_SCROLL_MOBILE_CLASS} {
    &, body {
      @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
        overflow: hidden;
      }
    }
  }
  body {
    min-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-width: ${STYLE_BREAKPOINT.MOBILE_MIN_WIDTH};
    }
  }
  
  html,
  body, 
  #__next {
    min-height:100%;
  }
  html,
  body {
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
  }
  
  img, video {
    vertical-align: middle;
  }

  a {
    color: ${STYLE_COLOR.PRIMARY01};
    text-decoration: none;
  }
`

export default GlobalStyle
